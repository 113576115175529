import React, { useState, useRef, useEffect } from 'react';

const GroupedSelect = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (key, description) => {
    onChange(key);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 text-left bg-white border rounded shadow focus:outline-none focus:ring-2 focus:ring-blue-400"
      >
        {value ? options[value.split('.')[0]][value.split('.')[1]].description : 'Select an action'}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-auto">
          {Object.entries(options).map(([group, actions]) => (
            <div key={group}>
              <div className="px-4 py-2 font-bold bg-gray-100">{group}</div>
              {Object.entries(actions).map(([key, { description }]) => (
                <div
                  key={key}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelect(`${group}.${key}`, description)}
                >
                  {description}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupedSelect;