import React, { useState } from 'react';
import { WiDaySunny } from "react-icons/wi";
import actionImpacts from './action-impacts.json';
import GroupedSelect from './GroupedSelect';

const ImpactCalculatorSection = ({ baseMetabolism, impactLedger, setImpactLedger, isOpen, toggleSection }) => {
  const [selectedAction, setSelectedAction] = useState('');

  const addImpact = () => {
    if (selectedAction) {
      const [group, action] = selectedAction.split('.');
      const newImpact = {
        id: Date.now(),
        ...actionImpacts[group][action],
        action: action
      };
      setImpactLedger([...impactLedger, newImpact]);
      setSelectedAction('');
    }
  };

  const removeImpact = (id) => {
    setImpactLedger(impactLedger.filter(impact => impact.id !== id));
  };

  const calculateTotalImpact = () => {
    return impactLedger.reduce((total, impact) => total + impact.impact, 0);
  };

  return (
    <div className="mb-6">
       <h2 
        className="text-2xl font-bold mb-2 cursor-pointer flex items-center"
        onClick={toggleSection}
      >
        Impact Calculator 
        <span className={`accordion-caret ${isOpen ? 'open' : ''}`}></span>
      </h2>
      {isOpen && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="space-y-4">
            <div>
              <label htmlFor="action" className="block mb-1 font-medium">Select Action</label>
              <GroupedSelect
                options={actionImpacts}
                value={selectedAction}
                onChange={setSelectedAction}
              />
            </div>
            <button 
              onClick={addImpact} 
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Add Impact
            </button>
            
            <div className="mt-4">
              <h3 className="font-bold mb-2">Impact Ledger:</h3>
              {impactLedger.map((impact) => (
                <div key={impact.id} className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded">
                  <span className="text-sm">{impact.description}</span>
                  <div className="flex items-center">
                    <span className="mr-2 text-sm">{impact.impact} cal</span>
                    <button 
                      onClick={() => removeImpact(impact.id)} 
                      className="text-red-500 hover:text-red-700 transition duration-200"
                    >
                      <WiDaySunny size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            
            {impactLedger.length > 0 && (
              <div className="text-sm">
                <p>Base Metabolism: {baseMetabolism} cal</p>
                <p>Total Impact: {calculateTotalImpact()} cal</p>
                <p className="font-bold text-lg">
                  Adjusted Metabolism: {baseMetabolism + calculateTotalImpact()} cal
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImpactCalculatorSection;