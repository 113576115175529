import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4 mt-8">
      <div className="container mx-auto text-center">
        <p>&copy; 2023 Metabolism Impact Calculator. All rights reserved.</p>
        <div className="mt-2">
          <Link to="/privacy-policy" className="text-blue-400 hover:text-blue-300 mx-2">Privacy Policy</Link>
          <Link to="/terms-of-service" className="text-blue-400 hover:text-blue-300 mx-2">Terms of Service</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;