import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for Metabolism Impact Calculator</h1>
      <p className="mb-4">Last updated: [Current Date]</p>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">1. Introduction</h2>
        <p>
          Welcome to the Metabolism Impact Calculator. We are committed to protecting your privacy and ensuring you have a positive experience on our application.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">2. Information Collection and Use</h2>
        <p>
          The Metabolism Impact Calculator is a locally-run application. This means:
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>We do not collect, store, or transmit any personal information.</li>
          <li>All calculations and data processing occur locally on your device.</li>
          <li>No information is sent to external servers or third parties.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">3. Data Storage</h2>
        <p>
          Any information you input into the calculator (such as age, weight, or height) is only stored temporarily in your device's memory while the application is running. This data is immediately discarded when you close the application or refresh the page.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">4. Cookies and Tracking</h2>
        <p>
          This application does not use cookies or any other tracking technologies.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">5. Third-Party Access</h2>
        <p>
          As all data is processed locally and no information is transmitted, no third parties have access to any information you input into the Metabolism Impact Calculator.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">6. Data Security</h2>
        <p>
          Since no data is stored or transmitted, there is no risk of data breaches or unauthorized access to your information through our application.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">7. Children's Privacy</h2>
        <p>
          Our application is not directed to children under the age of 13, and we do not knowingly collect or store information from children under 13.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">8. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-2">9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:{' '}
          <a href="mailto:stevenpwalsh@gmail.com" className="text-blue-500 hover:text-blue-700">
            stevenpwalsh@gmail.com
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;