import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { generateWeightLossData, formatWeight } from './utils';

const WeightLossProjectionSection = ({ personalInfo, baseMetabolism, impactLedger, isOpen, toggleSection }) => {
  const data = generateWeightLossData(personalInfo, baseMetabolism, impactLedger);

  return (
    <div>
      <h2 
        className="text-2xl font-bold mb-2 cursor-pointer flex items-center"
        onClick={toggleSection}
      >
        Weight Loss Projection 
        <span className={`accordion-caret ${isOpen ? 'open' : ''}`}></span>
      </h2>
      {isOpen && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="w-full" style={{ height: '50vh', minHeight: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" label={{ value: 'Weeks', position: 'insideBottomRight', offset: -5 }} />
                <YAxis 
                  domain={[dataMin => (Math.floor(dataMin) - 1), dataMax => (Math.ceil(dataMax) + 1)]}
                  tickFormatter={(value) => formatWeight(value, personalInfo.useMetric)}
                  label={{ value: `Weight (${personalInfo.useMetric ? 'kg' : 'lbs'})`, angle: -90, position: 'insideLeft' }}
                />
                <Tooltip formatter={(value) => formatWeight(value, personalInfo.useMetric)} />
                <Legend />
                <ReferenceLine y={personalInfo.weight} stroke="red" strokeDasharray="3 3" />
                <Line type="monotone" dataKey="current" stroke="#8884d8" name="Current Diet" />
                <Line type="monotone" dataKey="adjusted" stroke="#82ca9d" name="Adjusted Diet" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeightLossProjectionSection;