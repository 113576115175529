import React, { useState, useEffect } from 'react';
import IntroductionSection from './IntroductionSection';
import PersonalInfoSection from './PersonalInfoSection';
import ImpactCalculatorSection from './ImpactCalculatorSection';
import WeightLossProjectionSection from './WeightLossProjectionSection';
import { calculateBaseMetabolism } from './utils';
import { FaCalculator, FaInfoCircle, FaCoffee } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

const MetabolismImpactCalculator = () => {
  const [personalInfo, setPersonalInfo] = useState({
    age: 30,
    gender: 'male',
    weight: 70,
    height: 170,
    activityLevel: 'moderate',
    calorieIntake: 2000,
    useMetric: true
  });
  const [impactLedger, setImpactLedger] = useState([]);
  const [openSections, setOpenSections] = useState({
    personalInfo: true,
    impactCalculator: false,
    weightLossProjection: false
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Simulate loading state
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleBuyMeACoffee = () => {
    // Replace this URL with your actual Buy Me a Coffee link
    window.open('https://buymeacoffee.com/stevenwalsh', '_blank');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-white shadow-md py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-center text-primary-color flex items-center justify-center">
            <FaCalculator className="mr-2" />
            Metabolism Impact Calculator
          </h1>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <IntroductionSection />

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-color"></div>
          </div>
        ) : (
          <>
            <PersonalInfoSection
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              isOpen={openSections.personalInfo}
              toggleSection={() => toggleSection('personalInfo')}
            />

            <ImpactCalculatorSection
              baseMetabolism={calculateBaseMetabolism(personalInfo)}
              impactLedger={impactLedger}
              setImpactLedger={setImpactLedger}
              isOpen={openSections.impactCalculator}
              toggleSection={() => toggleSection('impactCalculator')}
            />

            <WeightLossProjectionSection
              personalInfo={personalInfo}
              baseMetabolism={calculateBaseMetabolism(personalInfo)}
              impactLedger={impactLedger}
              isOpen={openSections.weightLossProjection}
              toggleSection={() => toggleSection('weightLossProjection')}
            />

            <div className="mt-8 text-center">
              <button 
                onClick={handleBuyMeACoffee}
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              >
                <FaCoffee className="mr-2" />
                Buy me a coffee
              </button>
            </div>

            <div className="mt-8 text-center">
              <p className="text-sm text-gray-600">
                This calculator provides estimates based on general formulas. 
                <FaInfoCircle 
                  className="inline-block ml-1 cursor-pointer" 
                  data-tooltip-id="result-info"
                  data-tooltip-content="Results may vary. Consult with a healthcare professional for personalized advice."
                />
              </p>
              <Tooltip id="result-info" />
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default MetabolismImpactCalculator;