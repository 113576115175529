import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Terms of Service for Metabolism Impact Calculator</h1>
      <p className="mb-4">Last updated: [Current Date]</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">1. Acceptance of Terms</h2>
      <p>By accessing and using the Metabolism Impact Calculator ("the Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the Service.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">2. Use of the Service</h2>
      <p>The Metabolism Impact Calculator is intended for informational and educational purposes only. It is not a substitute for professional medical advice, diagnosis, or treatment.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">3. Disclaimer of Warranties</h2>
      <p>The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">4. Limitation of Liability</h2>
      <p>In no event shall the Metabolism Impact Calculator, its operators, or any related parties be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">5. User Responsibilities</h2>
      <p>You are responsible for ensuring the accuracy of the information you input into the calculator. The Service does not verify or validate the information you provide.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">6. Changes to Terms</h2>
      <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms periodically for changes.</p>
      
      <h2 className="text-2xl font-bold mt-6 mb-2">7. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:stevenpwalsh@gmail.com" className="text-blue-500 hover:text-blue-700">stevenpwalsh@gmail.com</a>.</p>
    </div>
  );
};

export default TermsOfService;