import React from 'react';

const IntroductionSection = () => {
  return (
    <section className="mb-8 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Optimize Your Metabolism</h2>
      <p className="text-gray-700 leading-relaxed">
        The Metabolism Impact Calculator helps you understand how lifestyle choices affect your metabolic health. By inputting your information and exploring various actions, you can see potential impacts on your metabolism and weight loss journey. Whether you're aiming to lose weight, maintain your current weight, or improve overall health, this tool provides valuable insights to support your wellness goals.
      </p>
    </section>
  );
};

export default IntroductionSection;