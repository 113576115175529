export const calculateBaseMetabolism = (personalInfo) => {
  const { age, gender, weight, height, activityLevel, useMetric } = personalInfo;
  
  // Check if any of the required values are missing or not numbers
  if (!age || !weight || !height || isNaN(age) || isNaN(weight) || isNaN(height)) {
    return 0; // Return 0 or some default value if the required inputs are invalid
  }

  let bmr;
  const weightKg = useMetric ? weight : weight / 2.20462;
  const heightCm = useMetric ? height : height * 2.54;

  if (gender === 'male') {
    bmr = 88.362 + (13.397 * weightKg) + (4.799 * heightCm) - (5.677 * age);
  } else {
    bmr = 447.593 + (9.247 * weightKg) + (3.098 * heightCm) - (4.330 * age);
  }

  const activityMultipliers = {
    sedentary: 1.2,
    light: 1.375,
    moderate: 1.55,
    active: 1.725,
    veryActive: 1.9
  };
  
  const multiplier = activityMultipliers[activityLevel] || 1.2; // Default to sedentary if invalid
  return Math.round(bmr * multiplier);
};

export const toggleUnit = (personalInfo, setPersonalInfo) => {
  const { weight, height, useMetric } = personalInfo;
  setPersonalInfo(prev => ({
    ...prev,
    useMetric: !useMetric,
    weight: useMetric ? Math.round(weight * 2.20462) : Math.round(weight / 2.20462),
    height: useMetric ? Math.round(height / 2.54) : Math.round(height * 2.54)
  }));
};

export const calculateTotalImpact = (impactLedger) => {
  return impactLedger.reduce((total, impact) => total + impact.impact, 0);
};

export const generateWeightLossData = (personalInfo, baseMetabolism, impactLedger) => {
  const { weight, calorieIntake, useMetric } = personalInfo;
  const currentDeficit = baseMetabolism - calorieIntake;
  const adjustedDeficit = baseMetabolism + calculateTotalImpact(impactLedger) - calorieIntake;
  const data = [];
  const caloriesPerUnit = useMetric ? 7700 : 3500; // 7700 calories per kg, 3500 per lb

  for (let week = 0; week <= 12; week++) {
    const currentWeightLoss = (currentDeficit * 7 / caloriesPerUnit) * week;
    const adjustedWeightLoss = (adjustedDeficit * 7 / caloriesPerUnit) * week;

    data.push({
      week,
      current: Number((weight - currentWeightLoss).toFixed(1)),
      adjusted: Number((weight - adjustedWeightLoss).toFixed(1)),
    });
  }

  return data;
};

export const formatWeight = (value, useMetric) => {
  return `${value.toFixed(1)} ${useMetric ? 'kg' : 'lbs'}`;
};