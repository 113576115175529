import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MetabolismImpactCalculator from './MetabolismImpactCalculator';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MetabolismImpactCalculator />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Analytics />
        <Footer />
      </div>
    </Router>
  );
}

export default App;