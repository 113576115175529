import React from 'react';
import { toggleUnit, calculateBaseMetabolism } from './utils';

const PersonalInfoSection = ({ personalInfo, setPersonalInfo, isOpen, toggleSection }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === 'age' || name === 'weight' || name === 'height' || name === 'calorieIntake') {
      updatedValue = value === '' ? '' : Number(value);
    }

    setPersonalInfo(prev => {
      const updatedInfo = { ...prev, [name]: updatedValue };
      return updatedInfo;
    });
  };

  const handleToggleUnit = () => {
    toggleUnit(personalInfo, setPersonalInfo);
  };

  const baseMetabolism = calculateBaseMetabolism(personalInfo);

  return (
    <div className="mb-6">
      <h2 
        className="text-2xl font-bold mb-2 cursor-pointer flex items-center"
        onClick={toggleSection}
      >
        Personal Information 
        <span className={`accordion-caret ${isOpen ? 'open' : ''}`}></span>
      </h2>
      {isOpen && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Base Metabolism Calculator</h3>
            <div className="flex items-center space-x-2">
              <span className={`text-sm ${!personalInfo.useMetric ? 'font-bold' : ''}`}>Imperial</span>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input 
                  type="checkbox" 
                  name="useMetric" 
                  id="useMetric" 
                  checked={personalInfo.useMetric}
                  onChange={handleToggleUnit}
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <label 
                  htmlFor="useMetric" 
                  className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                ></label>
              </div>
              <span className={`text-sm ${personalInfo.useMetric ? 'font-bold' : ''}`}>Metric</span>
            </div>
          </div>
          <div className="space-y-4">
            {['age', 'gender', 'weight', 'height', 'activityLevel', 'calorieIntake'].map((field) => (
              <div key={field}>
                <label htmlFor={field} className="block mb-1 font-medium">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                  {field === 'weight' && ` (${personalInfo.useMetric ? 'kg' : 'lbs'})`}
                  {field === 'height' && ` (${personalInfo.useMetric ? 'cm' : 'inches'})`}
                </label>
                {field === 'gender' || field === 'activityLevel' ? (
                  <select
                    id={field}
                    name={field}
                    value={personalInfo[field]}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    {field === 'gender' ? (
                      <>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </>
                    ) : (
                      <>
                        <option value="sedentary">Sedentary</option>
                        <option value="light">Light</option>
                        <option value="moderate">Moderate</option>
                        <option value="active">Active</option>
                        <option value="veryActive">Very Active</option>
                      </>
                    )}
                  </select>
                ) : (
                  <div className="relative">
                    <input
                      id={field}
                      name={field}
                      type="number"
                      value={personalInfo[field]}
                      onChange={handleInputChange}
                      className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {(field === 'weight' || field === 'height') && (
                      <span className="absolute right-3 top-2 text-gray-400">
                        {field === 'weight' ? (personalInfo.useMetric ? 'kg' : 'lbs') : (personalInfo.useMetric ? 'cm' : 'in')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div>
              <p className="font-bold text-lg">Estimated Daily Calorie Burn: {baseMetabolism} calories</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalInfoSection;